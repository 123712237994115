<!--
 * @Author: your name
 * @Date: 2021-04-23 13:35:59
 * @LastEditTime: 2021-04-23 13:46:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\ErrorPage\ErrorPageIndex.vue
-->

<template>
  <div class='ErrorPageIndex'>
      您还没有学科哦，请赶快到"个人中心"进行任教吧  : )
  </div>
</template>
<script>

export default {
  name: 'ErrorPageIndex',
  props:[],
  components: {},
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style lang='less' scoped>
.ErrorPageIndex{
    text-align: center;
    line-height: 100vh;
    font-size: 30px;
}
</style>
